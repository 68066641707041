var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-expand-lg"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/"},nativeOn:{"click":function($event){return _vm.closeMenu()}}},[_c('img',{staticClass:"logo d-none d-lg-block",attrs:{"src":require("@/assets/logo-desktop.png"),"alt":"logo"}}),_c('img',{staticClass:"logo-mob d-lg-none",attrs:{"src":require("@/assets/logo-simple.svg"),"alt":"logo"}})]),_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","aria-expanded":"false","aria-label":"Toggle navigation"},on:{"click":_vm.toggleMenu}},[_c('img',{staticClass:"hamburger",attrs:{"src":require("@/assets/hamburger.svg"),"alt":"menu"}})]),_c('div',{class:[
      'collapse',
      'navbar-collapse',
      'navbar-container',
      _vm.menuOpen ? 'show' : '',
    ]},[_c('ul',{staticClass:"navbar-nav main-nav"},[_c('li',{staticClass:"spacer"}),_vm._l((_vm.menuItems),function(item,index){return _c('li',{key:index,class:['nav-item', index === _vm.submenuOpen ? 'active' : '']},[(item.children && item.children.length > 0)?_c('div',{staticClass:"nav-item-content",on:{"click":function($event){return _vm.openSubmenu(index)}}},[_c('span',{staticClass:"nav-link"},[_vm._v(_vm._s(item.label))]),_vm._m(0,true)]):_c('div',[_c('router-link',{staticClass:"nav-link",attrs:{"to":item.url},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_vm._v(_vm._s(item.label))])],1),(item.children && item.children.length > 0)?_c('ul',{class:['submenu', _vm.submenuOpen === index ? 'active': '']},_vm._l((item.children),function(child,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":child.url},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_vm._v(_vm._s(child.label))])],1)}),0):_vm._e()])}),_c('li',{staticClass:"spacer"})],2),_c('ul',{staticClass:"navbar-nav"},[_c('li',[_c('router-link',{staticClass:"cta cta-primary mx-lg-4",attrs:{"to":"/for-students"},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_vm._v("For Students")])],1),_c('li',[_c('router-link',{staticClass:"cta cta-secondary",attrs:{"to":"/organizations"},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_vm._v("For Organizations")])],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"chevron-submenu"},[_c('img',{attrs:{"src":require("@/assets/chevron-green.svg"),"alt":"open submenu"}})])
}]

export { render, staticRenderFns }